
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "CurrencyTariffSelect",
  props: {
    currency_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:currency_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const currenciesData = computed(() => store.getters.Currencies);
    const inputCurrency = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isCurrencyRequired = (value) => {
      if (props.required && !value) {
        return t("rcurrency");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "currency",
      isCurrencyRequired
    );

    const getCurrencies = () => {
      if (props.load && !currenciesData.value.length) {
        ApiService.query(`/api/currencies/lists`, {
          params: {
            per_page: 5,
            name: "",
          },
        }).then(({ data }) => {
          store.commit("setCurrency", data.currencies);
          inputCurrency.value.list = data.currencies;
          inputCurrency.value.options = data.currencies;
        });
      } else {
        inputCurrency.value.list = currenciesData.value;
        inputCurrency.value.options = currenciesData.value;
      }
    };
    const selectCurrency = (query) => {
      if (query !== "") {
        ApiService.query(`/api/currencies/lists`, {
          params: {
            per_page: 5,
            name: query,
          },
        }).then(({ data }) => {
          data.currencies.forEach((item) => {
            store.commit("addCurrency", item);
          });
          inputCurrency.value.list = currenciesData.value;
          inputCurrency.value.options = data.currencies;
        });
      } else {
        inputCurrency.value.options = currenciesData.value;
      }
    };

    watch(
      () => props.currency_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => currenciesData.value,
      (first) => {
        inputCurrency.value.list = first;
        inputCurrency.value.options = first;
      }
    );

    onMounted(() => {
      element_id.value = props.currency_id;
      if (props.mounted) {
        getCurrencies();
      } else {
        inputCurrency.value.list = currenciesData.value;
        inputCurrency.value.options = currenciesData.value;
      }
    });

    return {
      element_id,
      inputCurrency,
      errorMessage,
      selectCurrency,
    };
  },
};
