
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "TaxesSelect",
  props: {
    tax_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:tax_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const current_user = computed(() => store.getters.currentUser);
    const taxesData = computed(() => store.getters.Taxes);
    const inputTaxes = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const inputTaxesType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isTaxRequired = (value) => {
      if (props.required && !value) {
        return t("rtaxes");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "taxes",
      isTaxRequired
    );

    const getTaxes = () => {
      const temp = localStorage.getItem("user") as any;
      const user = JSON.parse(temp);
      let offices = [] as any;
      ApiService.query("/api/offices/lists", {
        params: {
          per_page: 30,
          users:
            current_user.value.id !== undefined
              ? [current_user.value.id]
              : [user.id],
        },
      }).then(({ data }) => {
        offices = data.offices;
        if (props.load) {
          ApiService.query(`/api/taxes/lists`, {
            params: {
              per_page: 10,
              offices: offices.map((o: any) => o.id),
              name: "",
            },
          }).then(({ data }) => {
            store.commit("setTaxes", data.taxes);
            inputTaxes.value.list = data.taxes;
            inputTaxes.value.options = data.taxes;
          });
        } else {
          inputTaxes.value.list = taxesData.value;
          inputTaxes.value.options = taxesData.value;
        }
      });
    };
    const selectTaxes = (query) => {
      if (query !== "") {
        inputTaxes.value.loading = true;
        setTimeout(() => {
          inputTaxes.value.loading = false;
          inputTaxes.value.options = inputTaxes.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputTaxes.value.options.length) {
            const temp = localStorage.getItem("user") as any;
            const user = JSON.parse(temp);
            let offices = [] as any;
            ApiService.query("/api/offices/lists", {
              params: {
                per_page: 30,
                users:
                  current_user.value.id !== undefined
                    ? [current_user.value.id]
                    : [user.id],
              },
            }).then(({ data }) => {
              offices = data.offices;
              ApiService.query(`/api/taxes/lists`, {
                params: {
                  per_page: 10,
                  offices: offices.map((o: any) => o.id),
                  name: query,
                },
              }).then(({ data }) => {
                data.taxes.forEach((item) => {
                  store.commit("addTaxes", item);
                });
                inputTaxes.value.list = taxesData.value;
                inputTaxes.value.options = data.taxes;
              });
            });
          }
        }, 200);
      } else {
        inputTaxes.value.options = taxesData.value;
      }
    };

    watch(
      () => props.tax_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => taxesData.value,
      (first) => {
        inputTaxes.value.list = first;
        inputTaxes.value.options = first;
      }
    );

    onMounted(() => {
      element_id.value = props.tax_id;
      if (props.mounted) {
        getTaxes();
      } else {
        inputTaxes.value.list = taxesData.value;
        inputTaxes.value.options = taxesData.value;
      }
    });

    return {
      element_id,
      inputTaxes,
      inputTaxesType,
      errorMessage,
      selectTaxes,
    };
  },
};
