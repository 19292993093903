import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.item.criteria.type == 'Object')
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 0,
          modelValue: $setup.element_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
          name: $props.item.name ? `${$props.item.name}` : `${$props.item.code}`,
          class: "custom-select chevron w-100 p-0",
          size: "large",
          clearable: "",
          placeholder: `${_ctx.$t('iselect')}`,
          filterable: "",
          remote: "",
          "remote-show-suffix": "",
          "reserve-keyword": "",
          "remote-method": $setup.selectElement,
          loading: $setup.inputElements.loading,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:item_id', $event)))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputElements.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name ? `${item.name}` : `${item.code}`,
                value: item.id
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.name ? `${item.id} ${item.name}` : `${item.id} ${item.code}`), 1)
                ]),
                _: 2
              }, 1032, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "name", "placeholder", "remote-method", "loading"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1)
  ]))
}