
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ElementsSelect",
  props: {
    item_id: {
      type: Number,
      default: undefined,
    },
    offices: {
      type: Array,
      default: undefined,
    },
    is_required: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["update:item_id"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const path = ref("");
    const name = ref("");
    const element_id = ref("");
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isElementRequired = (value) => {
      if (props.item.is_required === 1 && !value) {
        return t("relement");
      }
      return true;
    };

    /* const { errorMessage, value } = useField(
      `${props.item.name}`,
      isElementRequired
    );*/

    const getElements = () => {
      ApiService.get(
        `api/tables_tariffs/${props.item.criteria.table_name_relationship}`
      ).then(({ data }) => {
        path.value = data.path;
        name.value = data.name;
        if (name.value !== "entities") {
          ApiService.query(`${path.value}`, {
            params: {
              per_page: 30,
            },
          }).then(({ data }) => {
            if (Array.isArray(data)) {
              inputElements.value.list = data;
              inputElements.value.options = data;
            } else {
              const key = Object.keys(data)[0];
              inputElements.value.list = data[`${key}`];
              inputElements.value.options = data[`${key}`];
            }
          });
        } else {
          ApiService.query(`${path.value}`, {
            params: {
              per_page: 30,
              type_entity_ids: [props.item.criteria.entity_type_id],
              office_id: props.offices,
            },
          }).then(({ data }) => {
            if (Array.isArray(data)) {
              inputElements.value.list = data;
              inputElements.value.options = data;
            } else {
              const key = Object.keys(data)[0];
              inputElements.value.list = data[`${key}`];
              inputElements.value.options = data[`${key}`];
            }
          });
        }
      });
    };
    const selectElement = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputElements.value.options.length) {
            ApiService.query(`${path.value}`, {
              params: {
                per_page: 5,
                type_entity_ids: [props.item.criteria.entity_type_id],
                office_id: props.offices,
                name: query,
              },
            }).then(({ data }) => {
              if (Array.isArray(data)) {
                data.forEach((x) => {
                  const index = inputElements.value.list.findIndex(
                    (y) => y.id === x.id
                  );
                  if (index === -1) {
                    inputElements.value.list.push(x);
                  }
                });
                inputElements.value.options = data;
              } else {
                const key = Object.keys(data)[0];
                data[`${key}`].forEach((x) => {
                  const index = inputElements.value.list.findIndex(
                    (y) => y.id === x.id
                  );
                  if (index === -1) {
                    inputElements.value.list.push(x);
                  }
                });
                inputElements.value.options = data[`${key}`];
              }
            });
          }
        }, 200);
      } else {
        inputElements.value.options = inputElements.value.list;
      }
    };

    watch(
      () => props.item_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => props.offices,
      (first) => {
        getElements();
      }
    );

    onMounted(() => {
      getElements();
      element_id.value = props.item_id;
    });

    return {
      element_id,
      inputElements,
      //errorMessage,
      selectElement,
    };
  },
};
