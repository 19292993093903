
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "TaxesTypeSelect",
  props: {
    type_data: {
      type: Array,
      default: () => [],
    },
    taxes_type_id: {
      type: Number,
      default: undefined,
    },
    tax_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:taxes_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const current_user = computed(() => store.getters.currentUser);
    const inputTaxes = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const inputTaxesType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isTaxRequired = (value) => {
      if (props.required && !value) {
        return t("rtaxes");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "taxestype",
      isTaxRequired
    );

    const getTaxes = () => {
      const temp = localStorage.getItem("user") as any;
      const user = JSON.parse(temp);
      let offices = [] as any;
      ApiService.query("/api/offices/lists", {
        params: {
          per_page: 30,
          users:
            current_user.value.id !== undefined
              ? [current_user.value.id]
              : [user.id],
        },
      }).then(({ data }) => {
        offices = data.offices;
        if (props.load) {
          ApiService.query(`/api/taxTypes/lists`, {
            params: {
              per_page: 10,
              offices: offices.map((o: any) => o.id),
              name: "",
            },
          }).then(({ data }) => {
            inputTaxes.value.list = data.taxes;
            inputTaxes.value.options = data.taxes;
          });
        }
      });
    };
    const selectTaxes = (query) => {
      console.log("inputTaxes.value.options", inputTaxes.value.options);
      console.log("inputTaxes.value.list", inputTaxes.value.list);
      if (query !== "") {
        console.log("query", query);
        inputTaxes.value.loading = true;
        setTimeout(() => {
          inputTaxes.value.loading = false;
          inputTaxes.value.options = inputTaxes.value.list.filter(
            (item: Record<string, any>) => {
              console.log("item.name", item.name);
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputTaxes.value.options.length) {
            inputTaxes.value.options = inputTaxes.value.list;
          }
        }, 200);
      } else {
        inputTaxes.value.options = inputTaxes.value.list;
      }
    };

    watch(
      () => props.taxes_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => props.type_data,
      (first) => {
        if (first.length) {
          inputTaxes.value.list = first;
          inputTaxes.value.options = first;
        }
      },
      { deep: true }
    );

    watch(
      () => props.tax_id,
      (first) => {
        if (first !== undefined) {
          getTaxes();
          ApiService.query(`/api/taxes_detail/lists`, {
            params: {
              taxes: [first],
              per_page: 10,
            },
          }).then(({ data }) => {
            inputTaxes.value.options = [];
            inputTaxes.value.list = [];
            data.taxes_details.forEach((x) => {
              inputTaxes.value.options.push({
                id: x.taxes_type_id,
                name: x.tax_type.name,
              });
              inputTaxes.value.list.push({
                id: x.taxes_type_id,
                name: x.tax_type.name,
              });
            });
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.taxes_type_id;
      }, 500);
      if (props.tax_id !== undefined) {
        ApiService.query(`/api/taxes_detail/lists`, {
          params: {
            taxes: [props.tax_id],
            per_page: 10,
          },
        }).then(({ data }) => {
          data.taxes_details.forEach((x) => {
            inputTaxes.value.options.push({
              id: x.taxes_type_id,
              name: x.tax_type.name,
            });
            inputTaxes.value.list.push({
              id: x.taxes_type_id,
              name: x.tax_type.name,
            });
          });
        });
      }
    });

    return {
      element_id,
      inputTaxes,
      inputTaxesType,
      errorMessage,
      selectTaxes,
    };
  },
};
